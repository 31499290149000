import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index"
  },
  {//首页
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  }, {//商品
    path: '/commodity',
    name: 'commodity',
    component: () => import('../views/commodity.vue'),
    meta: {
      keepAlive: true
    }
  }, {//商品详情
    path: '/productDeta',
    name: 'productDeta',
    component: () => import('../views/productDeta.vue')
  }, {//登录
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }, {//注册
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  }, {//忘记密码
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('../views/forgotPassword.vue')
  }, {//购物车
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: () => import('../views/shoppingCart.vue')
  }, {//结账
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/checkout.vue')
  }, {//购买
    path: '/buyNow',
    name: 'buyNow',
    component: () => import('../views/buyNow.vue')
  }, {//会员中心
    path: '/memberCenter',
    name: 'memberCenter',
    component: () => import('../views/memberCenter.vue')
  }, {//个人资料
    path: '/personalData',
    name: 'personalData',
    component: () => import('../views/personalData.vue')
  }, {//订单管理
    path: '/orderManagement',
    name: 'orderManagement',
    component: () => import('../views/orderManagement.vue')
  }, {//订单详情
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/orderDetails.vue')
  }, {//付款方式
    path: '/paymentMethod',
    name: 'paymentMethod',
    component: () => import('../views/paymentMethod.vue')
  }, {//常见问答
    path: '/commonQa',
    name: 'commonQa',
    component: () => import('../views/commonQa.vue')
  }, {//新手上路
    path: '/novice',
    name: 'novice',
    component: () => import('../views/novice.vue')
  }, {//内容管理
    path: '/article',
    name: 'article',
    component: () => import('../views/article.vue')
  }, {//搜索结果
    path: '/searchResult',
    name: 'searchResult',
    component: () => import('../views/searchResult.vue')
  },
  {//支付成功
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/paySuccess.vue')
  },
  {//重置密码
    path: '/resetpPassword',
    name: 'resetpPassword',
    component: () => import('../views/resetpPassword.vue')
  },
  {//订单支付
    path: '/orderCheckout',
    name: 'orderCheckout',
    component: () => import('../views/orderCheckout.vue')
  },
	{//新增地址
	  path: '/newAddress',
	  name: 'newAddress',
	  component: () => import('../views/newAddress.vue')
	},
  {//编辑地址
    path: '/editAddress',
    name: 'editAddress',
    component: () => import('../views/editAddress.vue')
  },
	{//我的地址
	  path: '/myAddress',
	  name: 'myAddress',
	  component: () => import('../views/myAddress.vue')
	}
]

const router = new VueRouter({
  mode: 'history',// 去掉＃
  routes
})

// 解决同一页面产生导航冗余，vue-router报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



export default router
